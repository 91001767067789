<template>
  <div></div>
</template>

<script>
export default {
  name: "HomeRedirect",
  created() {
    if (this.$i18n.locale === "fr") {
      this.$router.push({ name: "Accueil" });
    } else {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>
